import * as React from 'react';
// import PropTypes from 'prop-types';

import CardArea from 'components/CardArea';
import Layout from 'components/Layout';
import Header from 'components/Header';
import Title from 'components/Title';

import { getSearch, gtmGenerateSolution, gtmMicPlacement } from 'utils/helpers';
import { graphql } from 'gatsby';
import routes from 'utils/routes';
import useIsMobile from 'hooks/useIsMobile';
import useOptions from 'pageComponents/microphonePlacement/useOptions';
import useShowApplications from 'hooks/useShowApplications';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { useQueryParam, StringParam } from 'use-query-params';

function MicrophonePlacement(props) {
  const { t } = useTranslation(['common', 'microphonePlacement', 'nav']);
  const isMobile = useIsMobile();
  const showApplications = useShowApplications();
  const [selectedValue, setSelectedValue] = useQueryParam(
    'micPlacement',
    StringParam
  );
  const [seating] = useQueryParam('seating', StringParam);
  const microphoneOptions = useOptions(t, seating);

  // On unmount - send gtm event
  React.useEffect(() => {
    return () => {
      gtmMicPlacement();
    };
    // eslint-disable-next-line
  }, []);

  function getButtonChildren() {
    if (!showApplications) {
      return isMobile
        ? t('certifiedSolutions:submitButtonMobile')
        : t('certifiedSolutions:submitButton');
    }
    return t('common:next');
  }

  const navProps = {
    backButton: {
      to: `${routes.seating}${getSearch()}`,
    },
    nextButton: {
      children: getButtonChildren(),
      disabled: !selectedValue,
      onClick: showApplications ? () => null : () => gtmGenerateSolution(),
      to: showApplications
        ? `${routes.applications}${getSearch()}`
        : `${routes.recommendations}${getSearch()}`,
    },
  };

  return (
    <Layout navProps={navProps}>
      <Header pageNumber={4} t={t} />
      <Title
        subTitle={t('common:globalSubTitle')}
        title={t('microphonePlacement:title')}
        tooltip={t('microphonePlacement:titleTooltip')}
      />
      <CardArea
        columns={3}
        handleSelect={setSelectedValue}
        selectedValue={selectedValue}
        values={microphoneOptions}
      />
    </Layout>
  );
}

MicrophonePlacement.propTypes = {};

MicrophonePlacement.defaultProps = {};

export default MicrophonePlacement;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        language: { eq: $language }
        ns: {
          in: ["certifiedSolutions", "common", "microphonePlacement", "nav"]
        }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
