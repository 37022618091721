import * as React from 'react';
import { CardDescription, CardTitle } from 'components/Card';

const isBrowser = typeof window !== 'undefined';

function useOptions(t, seating) {
  const options = [
    {
      value: 'roaming',
      content: (
        <>
          <CardTitle>{t('microphonePlacement:roaming')}</CardTitle>
          <CardDescription>
            {t('microphonePlacement:roamingDesc')}
          </CardDescription>
        </>
      ),
    },
    {
      value: 'table',
      content: (
        <>
          <CardTitle>{t('microphonePlacement:table')}</CardTitle>
          <CardDescription>
            {t('microphonePlacement:tableDesc')}
          </CardDescription>
        </>
      ),
    },
    {
      value: 'wall',
      content: (
        <>
          <CardTitle>{t('microphonePlacement:wall')}</CardTitle>
          <CardDescription>{t('microphonePlacement:wallDesc')}</CardDescription>
        </>
      ),
    },
    {
      value: 'ceiling',
      content: (
        <>
          <CardTitle>{t('microphonePlacement:ceiling')}</CardTitle>
          <CardDescription>
            {t('microphonePlacement:ceilingDesc')}
          </CardDescription>
        </>
      ),
    },
    {
      value: 'nopreference',
      content: <CardTitle>{t('microphonePlacement:noPref')}</CardTitle>,
    },
  ];

  const [filteredOptions, setFilteredOptions] = React.useState(options);

  React.useEffect(() => {
    if (isBrowser && seating === 'theater') {
      setFilteredOptions(
        options.filter(function (option) {
          return option.value !== 'roaming' && option.value !== 'table';
        })
      );
    }
    // eslint-disable-next-line
  }, [seating]);

  return filteredOptions;
}

export default useOptions;
